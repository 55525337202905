@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Ubuntu';
    font-weight: 600;
    font-style: italic;
    src: url('./assets/fonts/Ubuntu-BoldItalic.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 600;
    src: url('./assets/fonts/Ubuntu-Bold.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-style: italic;
    src: url('./assets/fonts/Ubuntu-MediumItalic.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 500;
    src: url('./assets/fonts/Ubuntu-Medium.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 400;
    font-style: italic;
    src: url('./assets/fonts/Ubuntu-Italic.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 400;
    src: url('./assets/fonts/Ubuntu-Regular.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 300;
    font-style: italic;
    src: url('./assets/fonts/Ubuntu-LightItalic.ttf');
  }

  @font-face {
    font-family: 'Ubuntu';
    font-weight: 300;
    src: url('./assets/fonts/Ubuntu-Light.ttf');
  }

  body {
    @apply text-gray-600 font-ubuntu text-base;
  }
}

#root,
.App {
  height: 100vh;
  /* background-color: #f3f5f7; */
  background-color: #f7f7fa;
  overflow: hidden;
}

body > #root > div {
  height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search {
  width: 28rem;
}

.no-overflow {
  overflow: hidden;
}

.no-margin {
  margin: 0 !important;
}

.side-nav {
  margin-top: 44px !important;
  width: auto !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.full-screen {
  height: 100vh;
}

.progress-bar {
  width: 100pt;
  float: right;
}

/* examinations */
.padding-top-bottom {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.container {
  display: inline;
}

b.status-green {
  color: rgb(88, 189, 88);
}

b.status-red {
  color: rgb(206, 76, 76);
}

b.status-yellow {
  color: rgb(231, 195, 94);
}

.apexcharts-menu-icon {
  visibility: hidden;
}

#movie-player {
  pointer-events: none;
  /* visibility: hidden; */
}
